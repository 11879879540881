
import { Component, Vue } from "vue-property-decorator";
import {
  IUserProfile,
  IUserProfileUpdate,
  IUserProfileCreate,
} from "@/interfaces";
import { dispatchGetUsers, dispatchCreateUser } from "@/store/admin/actions";

@Component
export default class CreateUser extends Vue {
  public valid = false;
  public nickname: string = "";
  public email: string = "";
  public isActive: boolean = true;
  public isSuperuser: boolean = false;
  public setPassword = false;
  public password1: string = "";
  public password2: string = "";

  public async mounted() {
    await dispatchGetUsers(this.$store);
    this.reset();
  }

  public reset() {
    this.password1 = "";
    this.password2 = "";
    this.nickname = "";
    this.email = "";
    this.isActive = true;
    this.isSuperuser = false;
    this.$validator.reset();
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const updatedProfile: IUserProfileCreate = {
        email: this.email,
        nickname: this.nickname,
      };
      if (this.email) {
        updatedProfile.email = this.email;
      }
      updatedProfile.is_active = this.isActive;
      updatedProfile.is_superuser = this.isSuperuser;
      updatedProfile.password = this.password1;
      await dispatchCreateUser(this.$store, updatedProfile);
      this.$router.push("/users");
    }
  }
}
